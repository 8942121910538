.header-color {
  background: #009fff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ec2f4b,
    #009fff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ec2f4b,
    #009fff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #009fff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ec2f4b,
    #009fff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ec2f4b,
    #009fff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  text-align: center;

  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}

.banner-text {
  background-color: white;
  opacity: 0.8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.projects-grid {
  display: flex;
}

/* Resume Page css */
.resume-right-col {
  background: #27221f;
  color: white;
  padding: 2em;
}
.avatar-img-resume {
  /* margin: 4em; */
  padding: 10%;
  padding-left: -10%;
  width: 80%;
  border-radius: 50%;
}

/* contact page css */

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: "Anton", sans-serif;
}

.contact-grid p {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
}

#footer-extension {
  height: 100vh;
}

/* landing page */

.avatar-img {
  margin: 2em;
  padding: 2em;
  height: 40vh;
  border-radius: 50%;
}

.landing-page-card {
  max-width: 80vw;
  min-width: 50vw;
  min-height: 50vh;
  margin: auto;
}

.landing-page-name {
  font-size: 4.5vh;
  font-weight: bold;
  padding: 2vh;
  color: black;
}

.landing-page-title {
  font-size: 3vh;
  padding: 1vh;
  font-weight: bold;
  color: black;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: black;
  font-size: 5em;
}

.particles {
  z-index: -1
}